@keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  #shimmer {
    height: 4px; /* Adjust the height of the divider */
    background: linear-gradient(
      to right,
      rgba(0, 123, 255, 0.5) 0%,
      rgba(0, 123, 255, 1) 50%,
      rgba(0, 123, 255, 0.5) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
  }
  